
import {
    DownOutlined,
    DownloadOutlined,
    UpOutlined
} from '@ant-design/icons-vue';

export default {
    components: {
        DownOutlined,
        DownloadOutlined,
        UpOutlined
    },
    data() {
        return {
            //url: `/admin/express_invoice/details/${this.$route.query.id}`,
            where: {},
            selection: [],
            // 费用名称列表
            charge_names: [],
            // 搜索表单是否展开
            searchExpand: false,
            // 导出loading状态
            exportLoading: false
        };
    },
    computed: {
        url() {
            return `/admin/express_invoice/details/${this.$route.query.id}`;
        },
        columns() {
            return [{
                    title: this.$t("登录账号"),
                    dataIndex: "user_account",
                    sorter: true,
                    width: 120,
                    customRender({
                        record
                    }) {
                        return record.user_account || '-';
                    }
                }, {
                    title: this.$t("公司名称"),
                    dataIndex: "company_name",
                    sorter: true,
                    width: 120,
                    customRender({
                        record
                    }) {
                        return record.company_name || '-';
                    }
                },
                {
                    title: this.$t("主单号"),
                    dataIndex: "master_tracking_number",
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t("订单费用"),
                    dataIndex: "order_charge",
                    sorter: true,
                    width: 100
                },
                /*{
                    title: this.$t("express_invoice.fields.package_charge"),
                    dataIndex: "package_charge",
                    sorter: true,
                    width: 100
                },*/
                {
                    title: this.$t("服务费"),
                    dataIndex: "service_fee",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t("分单号"),
                    dataIndex: "tracking_number",
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t("系统尺寸/重量"),
                    sorter: true,
                    width: 200,
                    customRender({
                        record
                    }) {
                        return `${record.package_length} x ${record.package_width} x ${record.package_height} ${record['dim_unit'] && record['dim_unit'].toLocaleLowerCase() || 'in'} - ${record.package_weight || 0} ${record['weight'] && record['weight'].toLocaleLowerCase() || 'lbs'}`;
                    }
                },
                {
                    title: this.$t("账单尺寸/重量"),
                    sorter: true,
                    width: 200,
                    customRender({
                        record
                    }) {
                        return `${record.dim_length} x ${record.dim_width} x ${record.dim_height} ${record['dim_unit'] && record['dim_unit'].toLocaleLowerCase() || 'in'} - ${record.dim_weight || 0} ${record['weight'] && record['weight'].toLocaleLowerCase() || 'lbs'}`;
                    }
                },
                {
                    title: this.$t("费用名称"),
                    dataIndex: "charge_name",
                    sorter: true
                },
                {
                    title: this.$t("单项费用"),
                    dataIndex: "invoice_charge",
                    sorter: true,
                    width: 100
                },
                {
                    title: this.$t('状态'),
                    dataIndex: 'order_status',
                    width: 120,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: this.$t("揽收日期"),
                    dataIndex: "pickup_date",
                    align: 'center',
                    sorter: true,
                    width: 120
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('theme/tabSetTitle', {
            fullPath: this.$route.fullPath,
            title: `${this.$t('账单明细')} (${this.$route.query.number})`
        });

        this.load_data();
    },
    methods: {
        /**
         * 刷新表格
         */
        reload() {
            console.log(this.$refs.table);
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /**
         * 重置搜索
         */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http.get(`/admin/express_invoice/details_export/${this.$route.query.id}`, {
                params: this.where
            }).then(res => {
                this.exportLoading = false;

                if (res.data.code === 0) {
                    const blob = this.base64ToBlob(res.data.data, 'application/vnd.ms-excel');
                    const fileName = `Invoices-${new Date().getTime()}.xls`;

                    if ("download" in document.createElement("a")) {
                        // 非IE下载
                        const el = document.createElement("a");

                        el.download = fileName;
                        el.style.display = "none";
                        el.href = URL.createObjectURL(blob);
                        document.body.appendChild(el);

                        el.click();

                        URL.revokeObjectURL(el.href);
                        document.body.removeChild(el);
                    } else {
                        // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.exportLoading = false;
                this.$message.error(e.message);
            });
        },
        /**
         * 加载基础数据
         */
        load_data() {
            this.$http.get(`/admin/express_invoice/charge_names/${this.$route.query.id}`).then(res => {
                if (res.data.code === 0) {
                    this.charge_names = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        base64ToBlob(text, content_type) {
            var byteString = atob(text);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], {
                type: content_type
            });
        }
    }
};
